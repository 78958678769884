<template>
    <v-container>
        <p class="display-1">Vielen Dank für ihre Bestellung!</p>
        <order-detail print-button :order="order"></order-detail>
    </v-container>
</template>

<script>
    import OrderDetail from "@/components/OrderDetail";
    import {mapActions,mapGetters} from "vuex";
    export default {
        name: "order-ID-print",
        components:{OrderDetail},
        computed: {
            order (){
                return this.$store.getters['orderStore/findOrder'](this.$route.params.id) || this.singleOrder
            },
            ...mapGetters({
                hasCheckedLogin: 'userStore/hasCheckedLogin',
                loggedIn: 'userStore/loggedIn',
                singleOrder: 'orderStore/singleOrder'
            })
        },
        methods: {
            ...mapActions({
                fetchSingleOrder: 'orderStore/fetchSingleOrder',
            }),
        },
        watch:{
            hasCheckedLogin(val){
                if(val && !this.loggedIn && this.order===undefined){
                    this.$router.push({name:'index'})
                }
            },
            loggedIn(val){
                if(val && this.order===undefined){
                    this.fetchSingleOrder(this.$route.params.id).catch(err=>{console.log(err)})
                }
            }
        },
        created() {
            if(this.order===undefined && this.loggedIn){
                this.fetchSingleOrder(this.$route.params.id).catch(err=>{console.log(err)})
            } else if (this.hasCheckedLogin && !this.loggedIn && this.order===undefined){
                this.$router.push({name:'index'})
            }
        }
    }
</script>

<style scoped>

</style>