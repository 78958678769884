<template>
    <div>
        <div v-if="order!==undefined">
            <p v-if="order.timestamp" class="subtitle-1 font-weight-medium text--primary pb-0 mb-0 mt-0 pt-0">{{order.timestamp.toLocaleTimeString()}} -
                {{order.timestamp.toLocaleDateString()}}</p>
            <p v-if="order.id">Bestellnummer: {{order.id.substring(0,6)}}</p>
            <p class="headline font-weight-medium text--primary pb-0 mb-0 mt-1 pt-0" >Adresse: </p>
            <p class="subtitle-1 text--primary pb-0 mb-0 mt-0 pt-0">
                {{order.address.name }}
            </p>
            <p class="subtitle-1 text--primary pb-0 mb-0 mt-0 pt-0">
                {{order.address.address }}
            </p>
            <p class="subtitle-1 text--primary pb-0 mb-0 mt-0 pt-0">
                {{order.address.city }}

            </p>
            <p class="subtitle-1 text--primary pb-0 mb-0 mt-0 pt-0">
                {{order.address.zipCode }}
            </p>
          <p class="subtitle-1 text--primary pb-0 mb-0 mt-0 pt-0">
            {{order.address.phoneNumber }}
          </p>
          <p class="subtitle-1 text--primary pb-0 mb-0 mt-0 pt-0">
            {{order.address.email }}
          </p>
            <p class="subtitle-1 text--primary pb-0 mb-0 mt-0 pt-0">
                {{order.address.companyName }}
            </p>
            <p class="subtitle-1 text--primary pb-0 mb-0 mt-0 pt-0">
              <span v-if="order.address.floor">Etage: </span>{{order.address.floor }}
            </p>
            <p class="headline font-weight-medium text--primary pb-0 mb-0 mt-1 pt-0" >Bestellung: </p>
            <div class="d-inline" v-for="(item, index) in order.items" :key="item.foodId + index">
                <v-divider v-if="index===0"></v-divider>
                <p class="title font-weight-regular text--primary pb-0 mb-0 mt-0 pt-0">
                    {{item.count}} {{item.name}} <span class="float-right">{{(item.totalPrice || getTotalPriceOfFood(item))/100 | currency}}</span>
                </p>
                    <div class="subtitle-1 font-italic font-weight-light pb-0 mb-0 mt-0 pt-0 d-inline">
                        <div class="d-inline" v-for="(key,index) in Object.keys(item.questions)" :key="`answer-${key}`">
                            {{unfinishedOrder ? item.questions[key].answer.name : item.questions[key].name}}
                          <span class="d-inline" v-if="item.questions[key]['chosenExtras']">
                            (
                            <div class="d-inline" v-for="(obj,innerIndex) in item.questions[key]['chosenExtras']" :key="`extras-${item.questions[key]}-${obj.name}`">
                              {{ obj.name }}
                              <div class="d-inline" v-if="innerIndex < item.questions[key]['chosenExtras'].length -1">,</div>
                            </div>
                            )
                          </span>
                          <span class="d-inline" v-if="unfinishedOrder && item.questions[key].answer['chosenExtras']">
                            (
                            <div class="d-inline" v-for="(obj,innerIndex) in item.questions[key].answer['chosenExtras']" :key="`extras-${item.questions[key]}-${obj.name}`">
                              {{ obj.name }}
                              <div class="d-inline" v-if="innerIndex < item.questions[key].answer['chosenExtras'].length -1">,</div>
                            </div>
                            )
                          </span>
                            <div class="d-inline" v-if="index < Object.keys(item.questions).length -1 || item.extras.length > 0">, </div>
                        </div>
                        <div class="d-inline" v-for="(obj,index) in item.extras" :key="`extras-${obj.name}`">
                            {{obj.name}}
                            <div class="d-inline" v-if="index < item.extras.length -1">, </div>
                        </div>
                    </div>
                <p class="subtitle-1 font-weight-light pb-0 mb-0 mt-0 pt-0" v-if="item.comment">
                    Kommentar : {{item.comment}}
                </p>
                <v-divider></v-divider>
            </div>
          <div v-if="order.discountCode && order.discountCode.type=='cash'">
            <v-divider></v-divider>
            <v-divider></v-divider>
            <p class="title font-weight-regular text--primary pb-0 mb-0 mt-0 pt-0">
              Summe: <span class="float-right">{{ (order.totalPriceOfItems || getCombinedPriceOfAllFood(order))/100 |currency}}</span>
            </p>
            <v-divider></v-divider>
            <p class="title font-weight-regular text--primary pb-0 mb-0 mt-0 pt-0">
              Rabatt: <span class="float-right">- {{order.discountCode.cashDiscount/100 |currency}}</span>
            </p>

          </div>
          <div v-if="order.discountCode && order.discountCode.type=='percent'">
            <v-divider></v-divider>
            <v-divider></v-divider>
            <p class="title font-weight-regular text--primary pb-0 mb-0 mt-0 pt-0">
              Summe: <span class="float-right">{{(order.totalPriceOfItems ||getCombinedPriceOfAllFood(order))/100 |currency}}</span>
            </p>
            <v-divider></v-divider>
            <p v-if="order.discountCode.cashDiscount" class="title font-weight-regular text--primary pb-0 mb-0 mt-0 pt-0">
              Rabatt: <span class="float-right">- {{order.discountCode.cashDiscount/100 |currency}}</span>
            </p>
            <p v-else class="title font-weight-regular text--primary pb-0 mb-0 mt-0 pt-0">
              Rabatt: <span class="float-right">- {{getTotalDiscount(order) /100 |currency}}</span>
            </p>
          </div>
          <p class="title text-right font-weight-regular text--primary pb-0 mb-0 mt-0 pt-0">
            <v-divider></v-divider>
            {{ ((order.totalPrice - order.deliveryFee) || getCombinedPriceOfAllFood(order) - getTotalDiscount(order)) / 100 |currency }}
          </p>
            <div v-if="order.comment">
                <p class="headline font-weight-medium text--primary pb-0 mb-0 mt-1 pt-0" >Kommentar: </p>
                <p class="subtitle-1 font-weight-light pb-0 mb-0 mt-0 pt-0">{{order.comment}}</p>
            </div>
          <div v-if="order.deliveryFee && order.deliveryFee !== 0">
            <p class="headline font-weight-medium text--primary pb-0 mb-0 mt-1 pt-0">
                Liefergebühr: {{order.deliveryFee/100 |currency}}
            </p>
          </div>
            <p class="headline font-weight-medium text--primary pb-0 mb-0 mt-1 pt-0">
                Gesamtpreis: {{(order.totalPrice || getTotalPriceOfOrder(order))/100 |currency}}
            </p>
            <p class="subtitle-1 font-weight-medium text--primary pb-0 mb-0 mt-1 pt-0" v-if="order.paymentOption === 'paypal'">
                Bezahlt mit Paypal
            </p>
            <p class="subtitle-1 font-weight-medium text--primary pb-0 mb-0 mt-1 pt-0" v-else-if="order.paymentOption === 'cash'">
                Bezahlt in Bar
            </p>
            <v-btn v-if="printButton" block class="d-print-none" @click="handlePrintRequest">Drucken</v-btn>
        </div>
        <v-layout align-center justify-center class="pt-5 pb-8" v-else>
            <v-progress-circular
                    indeterminate
                    size="80"
                    color="primary"
            ></v-progress-circular>
        </v-layout>
    </div>
</template>

<script>
    export default {
        name: "OrderDetail",
        props: {
            order: Object,
            printButton: Boolean,
            unfinishedOrder: Boolean,
        },
        methods: {
            handlePrintRequest() {
                let routeData = this.$router.resolve({name: 'order-ID-print', params: {id: this.order.id}});
                window.open(routeData.href, '_blank');
            },
          //This gets the price of a single food item
            getTotalPriceOfFood(obj){
                let price = Number(obj.price);
                obj.extras.forEach(extra => {
                    price += Number(extra.price);
                });
                Object.values(obj.questions).forEach(question => {
                  if (question.answer && question.answer.price) {
                    price += Number(question.answer.price);
                  }
                  if(question.answer && question.answer['chosenExtras']){
                    question.answer['chosenExtras'].forEach(x => {
                      price += Number(x.price)
                    })
                  }
                })
                price = price*obj.count
                return price
            },
          //This gets the price of all food items together
          getCombinedPriceOfAllFood(order){
            let price = 0
            order.items.forEach(obj => {
              price += this.getTotalPriceOfFood(obj)
            })
            return price
          },
          //This gets the total discount on the food items
          getTotalDiscount(order){
            let discount = 0
            if(order.discountCode){
              if(order.discountCode.type=='cash'){
                discount = order.discountCode.cashDiscount
              } else if(order.discountCode.type=='percent') {
                discount = Math.ceil(this.getCombinedPriceOfAllFood(order) * (order.discountCode.percentOff / 100 ))
              }
            }
            return discount
          },
          //This gets the total price that has to be paid in the end.
            getTotalPriceOfOrder(order){
                let price = this.getCombinedPriceOfAllFood(order)
                if(order.deliveryFee){
                    price += order.deliveryFee
                }
                price -= this.getTotalDiscount(order)
                return price
            },

        }
    }
</script>

<style scoped>

</style>
